.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}


.screen {
  background-color: #222222;
  max-width: 100%;
  width: 698px;
  height: 392px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  position: relative;
}

@media screen and (max-width: 768px) {
  .screen {
    width: 100%;
    height: unset;
    aspect-ratio: 16 / 9;
  }
}

.overlay {
  position: absolute;
  width: 110%;
  left: -5%;
  bottom: -40%;
}

.countdown {
  color: #5FADF5;
  text-align: center;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 48px;
}

.countdown--blur {
  filter: blur(4.5px);
  position: absolute;
  left: 0;
}

.countdown__container {
  position: relative;
}

.play {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;

  width: 100%;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
}

.volume__controls {
  background: none;
  border: none;
  outline: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 10px;
}
@keyframes style_flicker__itnmk {
  0% {
    opacity: 0.27861;
  }
  5% {
    opacity: 0.34769;
  }
  10% {
    opacity: 0.23604;
  }
  15% {
    opacity: 0.90626;
  }
  20% {
    opacity: 0.18128;
  }
  25% {
    opacity: 0.83891;
  }
  30% {
    opacity: 0.65583;
  }
  35% {
    opacity: 0.67807;
  }
  40% {
    opacity: 0.26559;
  }
  45% {
    opacity: 0.84693;
  }
  50% {
    opacity: 0.96019;
  }
  55% {
    opacity: 0.08594;
  }
  60% {
    opacity: 0.20313;
  }
  65% {
    opacity: 0.71988;
  }
  70% {
    opacity: 0.53455;
  }
  75% {
    opacity: 0.37288;
  }
  80% {
    opacity: 0.71428;
  }
  85% {
    opacity: 0.70419;
  }
  90% {
    opacity: 0.7003;
  }
  95% {
    opacity: 0.36108;
  }
  100% {
    opacity: 0.24387;
  }
};

.style_scanlines__2j_yP {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  bottom: 20px;
  max-width: 100%;
  z-index: -5;
}

.style_scanlines__2j_yP:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  animation: style_flicker__itnmk 0.15s infinite;
}

.style_scanlines__2j_yP:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(
      rgba(18, 16, 16, 0) 50%,
      rgba(0, 0, 0, 0.25) 50%
    ),
    linear-gradient(
      90deg,
      rgba(255, 0, 0, 0.06),
      rgba(0, 255, 0, 0.02),
      rgba(0, 0, 255, 0.06)
    );
  z-index: 2;
  background-size: 100% 2px, 3px 100%;
  pointer-events: none;
}

.style_container__eOLGZ {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483649;
  pointer-events: none;
  background: none;
  mix-blend-mode: multiply;
  will-change: opacity;
}

.style_mask__Hn2PA {
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  will-change: opacity;
  background: none;
  z-index: 21474836499;
}
.style_container__AOUgq {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}


.style_screen__xNoHj {
  background-color: #222222;
  max-width: 100%;
  width: 698px;
  height: 392px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  position: relative;
}

@media screen and (max-width: 768px) {
  .style_screen__xNoHj {
    width: 100%;
    height: unset;
    aspect-ratio: 16 / 9;
  }
}

.style_overlay__d7i4y {
  position: absolute;
  width: 110%;
  left: -5%;
  bottom: -40%;
}

.style_countdown__12YiP {
  color: #5FADF5;
  text-align: center;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 48px;
}

.style_countdown--blur__zybgg {
  filter: blur(4.5px);
  position: absolute;
  left: 0;
}

.style_countdown__container___a4mW {
  position: relative;
}

.style_play___r2tp {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;

  width: 100%;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
}

.style_volume__controls__soCy2 {
  background: none;
  border: none;
  outline: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: 10px;
}
@font-face {
font-family: '__digitalNumbersFont_c052fc';
src: url(/_next/static/media/b2f48db7c14615a4-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__digitalNumbersFont_Fallback_c052fc';src: local("Arial");ascent-override: 55.56%;descent-override: 18.08%;line-gap-override: 0.00%;size-adjust: 175.32%
}.__className_c052fc {font-family: '__digitalNumbersFont_c052fc', '__digitalNumbersFont_Fallback_c052fc'
}

